// Entry point for the build script in your package.json


document.addEventListener("turbo:load", () => {
  
  const showAccounts = document.getElementById('show-accounts');
  const accountList = document.getElementById('account-list');

  if (showAccounts && accountList) {
    showAccounts.addEventListener('dblclick', () => {
      accountList.classList.toggle('hidden');
    });

    showAccounts.addEventListener('click', () => {
      accountList.classList.add('hidden');
    });
  }
});
